import _ from "lodash";

export const NOTES_BY_KEY = {
  a: ["A"],
  aSharp: ["A#", "B♭"],
  b: ["B"],
  c: ["C"],
  cSharp: ["C#", "D♭"],
  d: ["D"],
  dSharp: ["D#", "E♭"],
  e: ["E"],
  f: ["F"],
  fSharp: ["F#", "G♭"],
  g: ["G"],
  gSharp: ["G#", "A♭"],
};

export const NOTE_KEYS = _.keys(NOTES_BY_KEY);
export const NOTES = _.values(NOTES_BY_KEY);

export const NOTE_KEYS_BY_VALUE = {
  A: "a",
  "A#": "aSharp",
  "B♭": "aSharp",
  B: "b",
  C: "c",
  "C#": "cSharp",
  "D♭": "cSharp",
  E: "e",
  F: "f",
  "F#": "fSharp",
  G: "g",
  "G♭": "gSharp",
  "A♭": "gSharp",
};

// returns keys of the notes for easy lookup (i.e. aSharp)
export const calculateNotesInKey = (tonicKey, keyType = "major") => {
  let currentIndex = NOTE_KEYS.indexOf(tonicKey);
  const stepsInKey =
    keyType === "major" ? STEPS_IN_MAJOR_KEY : STEPS_IN_MINOR_KEY;
  const noteKeys = [tonicKey];

  for (let i = 0; i < stepsInKey.length - 1; i++) {
    currentIndex = (currentIndex + stepsInKey[i]) % 12;
    noteKeys.push(NOTE_KEYS[currentIndex]);
  }
  return noteKeys;
};

export const calculateChordsInKey = (
  tonicKey,
  keyType = "major",
  seventh = false
) => {
  const notesInKey = calculateNotesInKey(tonicKey, keyType);
  return notesInKey.map((noteKey, index) => {
    const chordByNoteKey = [noteKey];
    chordByNoteKey.push(notesInKey[(index + 2) % 7]);
    chordByNoteKey.push(notesInKey[(index + 4) % 7]);
    if (seventh) {
      chordByNoteKey.push(notesInKey[(index + 6) % 7]);
    }
    return chordByNoteKey.map((noteKey) => NOTES_BY_KEY[noteKey]);
  });
};

export const STEPS_IN_MAJOR_KEY = [
  2, // whole
  2, // whole
  1, // half
  2, // whole
  2, // whole
  2, // whole
  1, // half
];

export const STEPS_IN_MINOR_KEY = [
  2, // whole
  1, // half
  2, // whole
  2, // whole
  1, // half
  2, // whole
  2, // whole
];

export const MAJOR_CHORD_TYPES = {
  I: "major",
  ii: "minor",
  iii: "minor",
  IV: "major",
  V: "major",
  vi: "minor",
  "vii°": "diminished",
};

export const MAJOR_CHORD_NUMERALS = Object.keys(MAJOR_CHORD_TYPES);
export const MAJOR_CHORD_VOICE = Object.values(MAJOR_CHORD_TYPES);

export const MINOR_CHORD_TYPES = {
  i: "minor",
  "ii°": "diminished",
  III: "major",
  iv: "minor",
  v: "minor",
  VI: "major",
  VII: "major",
};

export const MINOR_CHORD_NUMERALS = Object.keys(MINOR_CHORD_TYPES);
export const MINOR_CHORD_VOICE = Object.values(MINOR_CHORD_TYPES);

/*
Examples:
Take a major.
Go a 3rd down to get relative minor.
It's the same notes just different starting point

F Major: F G A A# C D E 
D Minor: D E F G A A# C 

CHORDS:
F A C
G A# D
A C E
A# D F
C E G
D F A
E G A#

-----
G Major: G A B C D E F# 
E Minor: E F# G A B C D 

CHORDS:
G B D
A C E
B D F#
C E G
D F# A
E G B
F# A Cs
*/
