import { NOTES_BY_KEY, NOTE_KEYS, calculateNotesInKey } from "./chordvoicings";
import "./Notes.css";

function Notes(props) {
  const notesInKey = calculateNotesInKey(props.tonicKey, props.keyType);

  const notes = NOTE_KEYS.map((noteKey) => {
    const note = NOTES_BY_KEY[noteKey];
    const noteIsInKey = notesInKey.indexOf(noteKey) > -1;
    return (
      <div
        onClick={() => props.setTonicKey(noteKey)}
        className={`Note ${noteIsInKey ? "in-key" : null} ${
          props.tonicKey === noteKey ? "tonic" : null
        } ${note.length === 1 ? "single" : "double"}`}
      >
        {note.length === 1 ? (
          <div>{note[0]}</div>
        ) : (
          <div>
            <div>{note[0]}</div>
            <div>{note[1]}</div>
          </div>
        )}
      </div>
    );
  });

  return (
    <div>
      <div className="Notes">{notes}</div>
    </div>
  );
}

export default Notes;
