import { useState } from "react";
import "./Information.css";

function Information() {
  const [visible, setVisible] = useState(false);
  return (
    <div className="Information">
      <button onClick={() => setVisible(!visible)}>i</button>
      <ul className={visible ? "visible" : "hidden"}>
        <li>major key pattern: wwhwwwh</li>
        <li>minor key pattern: whwwhww</li>
        <li>major keys: I ii iii IV V vi vii°</li>
        <li>minor keys: i ii° III iv v VI VII</li>
      </ul>
    </div>
  );
}

export default Information;
