import "./App.css";
import Notes from "./Notes";
import Information from "./Information";
import React from "react";
import { setTonicKey, setKeyType, setSeventh } from "./redux/store";
import { connect } from "react-redux";
import Chords from "./Chords";
import { NOTES_BY_KEY } from "./chordvoicings";

function App(props) {
  const tonicKey = props.tonicKey;
  return (
    <div className="App">
      <Information />
      <header className="App-header">
        <h1>Chord Voicings</h1>
        <h2>Keys, chords, and more</h2>
        <h3>
          Key: {NOTES_BY_KEY[props.tonicKey].join(",").replace(",", "/")}{" "}
          {props.keyType}
        </h3>
        <div>
          <button
            className={`${props.keyType === "major" && "selected"}`}
            onClick={() => props.setKeyType("major")}
          >
            Major
          </button>
          /
          <button
            className={`${props.keyType === "minor" && "selected"}`}
            onClick={() => props.setKeyType("minor")}
          >
            Minor
          </button>
        </div>
        <div>
          <button onClick={() => props.setSeventh()}>Seventh Chords</button>
        </div>
      </header>
      <Notes
        tonicKey={tonicKey}
        keyType={props.keyType}
        setTonicKey={props.setTonicKey}
      />
      <Chords
        tonicKey={tonicKey}
        keyType={props.keyType}
        seventh={props.seventh}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    tonicKey: state.tonicKey,
    keyType: state.keyType,
    seventh: state.seventh,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTonicKey: (tonicKey) => {
    dispatch(setTonicKey(tonicKey));
  },
  setKeyType: (keyType) => {
    dispatch(setKeyType(keyType));
  },
  setSeventh: (keyType) => {
    dispatch(setSeventh(keyType));
  },
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
