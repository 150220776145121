import {
  MAJOR_CHORD_NUMERALS,
  MAJOR_CHORD_VOICE,
  MINOR_CHORD_NUMERALS,
  MINOR_CHORD_VOICE,
  calculateChordsInKey,
} from "./chordvoicings";
import "./Chords.css";

function Chords(props) {
  const isMajor = props.keyType === "major";
  const chordNumerals = isMajor ? MAJOR_CHORD_NUMERALS : MINOR_CHORD_NUMERALS;
  const quality = isMajor ? MAJOR_CHORD_VOICE : MINOR_CHORD_VOICE;
  const chordsInKey = calculateChordsInKey(
    props.tonicKey,
    props.keyType,
    props.seventh
  );
  const chords = chordsInKey.map((chord, index) => {
    const tonic = chord[0];
    const chordComponent = chord.map((note) => {
      return (
        <div className="Chord-Note">
          {note.length === 1 ? (
            <div>{note[0]}</div>
          ) : (
            <div>
              <div>{note[0]}</div>
              <div>{note[1]}</div>
            </div>
          )}
        </div>
      );
    });
    return (
      <li className="Chord">
        <div className="Chord-Numeral">{chordNumerals[index]}</div>
        <div className="Chord-Voice">{`${tonic.join(",").replace(",", "/")} ${
          quality[index]
        }${props.seventh ? "7" : ""}:`}</div>
        {chordComponent}
      </li>
    );
  });
  return (
    <div className="Chords">
      <ul>{chords}</ul>
    </div>
  );
}

export default Chords;
