import { createStore, combineReducers } from "redux";
import _ from "lodash";

const initialState = {
  tonicKey: "c",
  keyType: "major", // major, minor, etc
  seventh: false,
};

// action types
export const SET_TONIC_KEY = "SET_TONIC_KEY";
export const SET_KEY_TYPE = "SET_KEY_TYPE";
export const SET_SEVENTH = "SET_SEVENTH";

// actions
export const setTonicKey = (tonicKey) => {
  return { type: SET_TONIC_KEY, tonicKey };
};
export const setKeyType = (keyType) => {
  return { type: SET_KEY_TYPE, keyType };
};
export const setSeventh = () => {
  return { type: SET_SEVENTH };
};

// reducers
export const tonicKey = (state = initialState, action) => {
  const copyOfState = _.cloneDeep(state);
  switch (action.type) {
    case SET_TONIC_KEY:
      return action.tonicKey;
    default:
      return copyOfState;
  }
};

export const keyType = (state = initialState, action) => {
  const copyOfState = _.cloneDeep(state);
  switch (action.type) {
    case SET_KEY_TYPE:
      return action.keyType;
    default:
      return copyOfState;
  }
};

export const seventh = (state = initialState, action) => {
  const copyOfState = _.cloneDeep(state);
  switch (action.type) {
    case SET_SEVENTH:
      return !copyOfState;
    default:
      return copyOfState;
  }
};

export const reducers = combineReducers({
  tonicKey,
  keyType,
  seventh,
});

// store
export default createStore(reducers, initialState);
